import {
	breakpoints,
	Spacing,
	UIPlatform,
	UITheme,
} from '@wearemojo/ui-constants';
import { Context, useContext } from 'react';
import {
	ColorSchemeName,
	ScaledSize,
	StyleSheet,
	Text,
	View,
} from 'react-native';

import flagDefaults from './flags';
import { UIContext, UIFormFactor } from './types';

export const DefaultWindowDimensions = {
	width: 320,
	height: 480,
	scale: 1,
	fontScale: 1,
};

export const createUIContext = ({
	windowDimensions = DefaultWindowDimensions,
	platform = UIPlatform.web,
	theme = UITheme.dark,
	formFactor,
	flags: flagOverrides,
	reduceTransparency = false,
}: Partial<
	UIContext & { windowDimensions: ScaledSize }
> = {}): Required<UIContext> => {
	const autoFormFactor = getFormFactor(windowDimensions);

	const flags = {
		...flagDefaults,
		...flagOverrides,
	};

	return {
		theme,
		platform,
		formFactor: formFactor ?? autoFormFactor,
		flags,
		reduceTransparency,
	};
};

export const getFormFactor = (windowDimensions: ScaledSize) => {
	return windowDimensions.width >= breakpoints.desktop
		? UIFormFactor.desktop
		: UIFormFactor.mobile;
};

export const getTheme = (_?: ColorSchemeName) => UITheme.dark;

export { flagDefaults };

export const DebugContextRender = ({ context }: { context: Context<any> }) => {
	const value = useContext(context);
	const isDarkTheme = value.theme === UITheme.dark;
	return (
		<View style={[debugStyles.root, isDarkTheme && debugStyles.rootDark]}>
			<Text
				style={[
					debugStyles.text,
					isDarkTheme && debugStyles.textDark,
					debugStyles.label,
				]}
			>
				Context value:
			</Text>
			<Text style={[debugStyles.text, isDarkTheme && debugStyles.textDark]}>
				{JSON.stringify(value, null, 2)}
			</Text>
		</View>
	);
};

const debugStyles = StyleSheet.create({
	root: {
		borderRadius: 5,
		backgroundColor: '#f6f6f6',
		padding: Spacing.dsDeviation__10,
		marginVertical: Spacing.dsDeviation__10,
	},
	rootDark: {
		backgroundColor: '#333',
	},
	text: {
		color: '#000',
	},
	textDark: {
		color: '#fff',
	},
	label: {
		fontWeight: '700',
	},
});
